<template>
  <div class="footer flex items-middle flex-center">
      <!-- <ul class="footer__list flex">
        <li class="footer__list-item">
          <a href="">首页</a>
        </li>
        <li class="footer__list-item">
          <a href="">关于我们</a>
        </li>
        <li class="footer__list-item">
          <a href="">优秀案例</a>
        </li>
        <li class="footer__list-item">
          <a href="">合作伙伴</a>
        </li>
        <li class="footer__list-item">
          <a href="">招贤纳士</a>
        </li>
        <li class="footer__list-item">
          <a href="">联系我们</a>
        </li>
      </ul> -->

  
      <div>
        <div class="footer__address text-center">
          Copyright © 2022-2023 湖南科恩信息技术有限公司
        </div>

        <div class="footer__code text-center pointer">
          <a target="_blank" href="https://beian.miit.gov.cn/?spm=a2cmq.17630000.J_9220772140.115.506a79fepiOEEw#/Integrated/index">湘ICP备20008691号-3</a>
        </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@include b(footer) {
  height: 80px;
  background: #5E5E5E;
  color: $--color-white;
  // @include e(list) {
  //   width: 85%;
  //   margin: 69px 0 18px 0;
  // }

  // @include e(list-item) {
  //   padding: 0 14px;
  //   text-align: center;
  //   border-right: 1px solid $--color-white;
  //   &:nth-last-child() {
  //     border: none;
  //   }
  //   a {
  //     color: $--color-white;
  //     &:hover {
  //       color: $--color-primary;
  //     }
  //   }
  // }
  @include e(address) {

  }

  @include e(code) {
    margin-top: 10px;
    text-decoration: underline;
    a {
      color: #fff;
    }
  }
}
</style>