<template>
  <div>
    Home
  </div>
</template>

<script>
// import { getList } from '@/api/test'
export default {
  // async created() {
  //   await getList()
  // }
}
</script>

<style>

</style>