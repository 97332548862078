<template>
  <div id="app">
    <Header />
    <!-- <div class="banner">
      <Header />
      <img src="./assets/images/banner.png" alt="" class="banner__img">
    </div> -->
  
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <contact />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Contact from '@/components/Contact'

export default {
  components: {
    Header,
    Footer,
    Contact
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/theme/common/index.scss";
#app {
  width: 100%;
  height: 100%;
  @include b(banner) {
    width: 100%;
    // height: 494px;
    // background-image: url('./assets/images/banner.png');
    // background-repeat: no-repeat;
    position: relative;
     @include e(img) {
       width: 100%;
     }
  }
}

</style>
