<template>
  <div class="contact" ref="contact" id="contact">
    <div class="item pointer" @click="onShowQrCode">
      <img src="@/assets/images/WX.png" alt="" class="contact__img">
      <div class="contact__qr animated fadeInLeft" v-show="isQrCodeShow">
        <img src="@/assets/images/wx-qr.png" alt="">
      </div>
    </div>
    <div class="item pointer" @click="onShowPhone">
      <img src="@/assets/images/phone.png" alt="" class="contact__img">
      <div class="contact__phone-num animated fadeInLeft" v-show="isPhoneShow">
        <img src="@/assets/images/phone-num.png" alt="" style="width:100%">
      </div>
    </div>
    <div class="item pointer" @click="onBackTop">
      <img src="@/assets/images/back-to-top.png" alt="" class="contact__img">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isQrCodeShow: false,
      isPhoneShow: false
    }
  },
  mounted() {
    document.addEventListener('click', this.onShowHide);
  },
  methods: {
    onShowQrCode() {
      this.isQrCodeShow = !this.isQrCodeShow;
      this.isQrCodeShow ? this.isPhoneShow = false : this.isPhoneShow = true
    },
    onShowPhone() {
      this.isPhoneShow = !this.isPhoneShow;
      this.isPhoneShow ? this.isQrCodeShow = false : this.isQrCodeShow = true
    },
    onBackTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },
    onShowHide(e) {
      let contactEle = document.getElementById('contact')
      if(!contactEle.contains(e.target)) {
        this.isQrCodeShow = false
        this.isPhoneShow = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(contact) {
    height: 30px;
    position: fixed;
    width: 50px;
    right: 29px;
    bottom: 38vh;
    @include e(img) {
      width: 52px;
      height: 52px;
    }
    @include e(qr) {
      position: absolute;
      left: -269%;
      top: -8%;
      width: 114px;
    }
    @include e(phone-num) {
      width: 150px;
      position: absolute;
      left: -301%;
      top: 182%;
    }
}
</style>