<template>
  <header class="header">
      <div class="container full-height flex space-between items-middle">
          <div class="items-middle flex">
            <img class="header__logo" src="@/assets/images/logo.png" alt="科恩信息" />
          </div>

          <!-- <input type="checkbox" id="check">
          <label for="check">
            <VIcon name="icon--shousuo" size="28" class="header__folder"/>
          </label> -->

          <VIcon name="icon--shousuo" size="28" class="header__folder" @click="onFolder"/>

          <nav :class="['header__nav','flex','items-middle']" :style="isNavHeightFolder ? nav : { height: '0px' }">
            <ul class="flex space-between items-middle">
              <li v-for="(navItem,navIndex) in navData" :key="navIndex" :class="[navIndex == currentIndex ? 'li-active' : '']" @click="onTapItem(navIndex)">
                <router-link :to="navItem.path" >
                  {{ navItem.title }}
                </router-link>
              </li>
            </ul>

            <div class="header__demand pointer flex items-middle" @click="onContactUs">
              <img src="@/assets/images/demand.png" alt="">
            </div>
          </nav>
      </div>
  </header>
</template>

<script>
import { getWindowWidth } from '@/utils/index'
export default {
  name: 'Header',
  data() {
    return {
      navData: [
        {
          title: '首页',
          path: '/homePage'
        },
        {
          title: '优秀案例',
          path: '/excellentCase'
        },
        // 招纳贤士
        {
          title: '优秀产品',
          path: '/recruitment'
        },
        {
          title: '联系我们',
          path: '/contact'
        },
        {
          title: '关于我们',
          path: '/about'
        }
      ],
      currentIndex: 0,
      isNavHeightFolder: false, // 导航是否收缩
      nav: {
          height:'210px'
      },
      isComputer: true
    }
  },
  methods: {
    onTapItem(index) {
      this.currentIndex = index;
      console.log('this.isComputer',this.isComputer);
      if(!this.isComputer) {
        this.isNavHeightFolder = false
      }
    },
    onFolder() {
      this.nav.height = '210px'
      this.isNavHeightFolder = !this.isNavHeightFolder
    },
    onContactUs() {
      this.$router.push({
        name: 'contact'
      })
    },
    onAdaptat() {
      getWindowWidth() > 1056 ? this.isNavHeightFolder = true : this.isNavHeightFolder = false
      getWindowWidth() > 1056 ? this.isComputer = true : this.isComputer = false
    }
  },
  created() {
    this.onAdaptat()
    window.addEventListener("resize", () => {
      this.onAdaptat()
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(header) {
    height: $--header-height;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    font-size: $--font-size-medium;
    font-weight: 500;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 0 80px;
    z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    @media (min-width: 1500px) {
      padding: 0 140px;
    }
    @include media-mobile {
      // background: $--color-black;
      border-radius: 0;
      padding: 0 20px;
    }
    @include e(logo) {
      width: 176px;
      @include media-mobile {
        width: 110px;
      }
    }
    // .heigh-zero {
    //   height: 0;
    // }
    @include e(nav) {
      transition: height .5s;
      overflow: hidden;
      z-index: 100;
      @include media-mobile {
        position: absolute;
        background: rgba($color: #000000, $alpha: 0.5);
        top: 66px;
        right: -47px;
        @include media-sm-mobile {
          right: -62px;
        }
        height: 0px;
        width: 192px;

        ul {
          width: 100vw !important;
          display: block;
          .li-active {
            background: rgba($color: #f5f5f5, $alpha: 0.5);
          }
          li {
            display: block;
            padding-left: 26px;
            @include media-sm-mobile {
              padding-left: 24px;
              font-size: 14px;
            }
            a {
              height: 38px !important;
              line-height: 38px !important;
              color: $--color-primary !important;
            }

            .router-link-active {
              border-bottom: 0px solid $--color-white !important;
            }
          }
        }
      }


      & > ul {
        width: 440px;
        min-width: 440px;
        li {
          a {
            color: $--color-white;
            display: inline-block;
            height: $--header-height;
            line-height: $--header-height;
            position: relative;
            transition: width .2s ease-in-out;
            &:hover {
              color: $--color-primary;
            }
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              width: 0;
              border-bottom: solid 2px;
              transition: width .2s ease-in-out;
              color: $--color-primary;
            }

            &:hover:before {
              width: 100%;
            }
          }
          .router-link-active {
            &:before {
              width: 100%;
            }
            // color: $--color-primary;
            // border-bottom: 2px solid $--color-primary;
            // font-weight: 700;
            // border-bottom-width: 2px;
            // border-bottom-style: solid;
            // border-bottom-color: $--color-primary;
          }
        }
      }
    }

    @include e(demand) {
      // width: 170px;
      // min-width: 170px;
      // height: 42px;
      // background: $--color-primary;
      // border-radius: $--border-radius-large;
      // color: $--color-white;
      // text-align: center;
      // line-height: 42px;
      margin-left: 52px;
      position: relative;
      top: 5px;
      transform: scale(0.9);
      @include media-mobile{
        display: none;
      }
    }

    @include e(folder) {
      display: none;
      color: $--color-primary;
      position: relative;
      z-index: 100;
      @include media-mobile {
          display: block;
        }
    }

    // #check {
    //   display: none;
    // }

    // #check:checked ~ nav {
    //   height: 300px;
    //   @include media-mobile {
    //     height: 255px;
    //   }
    //   @include media-sm-mobile {
    //     height: 228px;
    //   }
    // }
}


</style>