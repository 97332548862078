import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/animate.min.css'

Vue.config.productionTip = false

// 图标组件
import VIcon from '@/components/VIcon'
Vue.component('VIcon', VIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
