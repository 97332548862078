// 获取滚动条距离 兼容写法
export const getScrollOffset = () => {
  if(window.pageXOffset) {
    return {
      left: window.pageXOffset,
      top: window.pageYOffset
    }
  } else {
    return {
      left: document.body.scrollLeft + document.documentElement.scrollLeft,
      top: document.body.scrollTop + document.documentElement.scrollTop
    }
  }
}

// 获取浏览器宽度
export const getWindowWidth = () => {
  return document.body.clientWidth
}